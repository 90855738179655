import { useContext } from 'preact/hooks'
import UserPreferencesContext from './UserPreferencesContext';

const useUserPreferences = () => {
  const userPreferencesContext = useContext(UserPreferencesContext);

  if (!userPreferencesContext) {
    throw new Error(
      "useUserPreferences must be used within a UserPreferencesProvider"
    );
  }
  return userPreferencesContext;
}

export default useUserPreferences;
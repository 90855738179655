const Tab = ({ children, active }) => {
  const activeClass = active ? 'active' : '';

  return (
    <div role="tabpanel" className={activeClass}>
      {children}
    </div>
  );
};

export default Tab;

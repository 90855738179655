export default function(query, noResultsLabel) {
  return query
    ? `
    <div class="autocomplete-item">
      <p class="place-info">
        ${noResultsLabel}
      </p>
    </div>
  `
    : null;
}

export function NotFoundComponent({ noResultsLabel }) {
  return (
    <li className="es-visible no-selectable">
      <p className="place-info">
        {noResultsLabel}
      </p>
    </li>
  )
}
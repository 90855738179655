import { Fragment } from 'preact';
import { selectIcon } from '../templates/place';
import { Text } from 'preact-i18n';

const BadgeRecommended = () => {
  const sparkleIcon = (
    <Fragment>
      <div
        className="icon-sparkle-top"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: selectIcon('stars', false),
        }}
      />

      <div
        className="icon-sparkle-bottom"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: selectIcon('stars', false),
        }}
      />
    </Fragment>
  );

  return (
    <div className="badge-recommended">
      <div className="badge-recommended-container">
        <p className="badge-recommended-text">
          <Text id="title.for_you" />
        </p>
        {sparkleIcon}
      </div>
    </div>
  );
};

export default BadgeRecommended;

/* global $ */
import 'script-loader!pickadate/lib/compressed/picker';
import 'script-loader!pickadate/lib/compressed/picker.date';

const dateFormatParsers = {
  'dd-mmm-yy': stringDate => {
    const { monthsShort } = $.fn.pickadate.defaults;

    const today = new Date();
    const [day, monthShort, yearShort] = stringDate.split('-');
    const month = monthsShort.indexOf(monthShort);
    const year = Number(
      `${today
        .getFullYear()
        .toString()
        .slice(0, 2)}${yearShort}`
    );

    return new Date(year, month, day);
  },
  'yyyy-mm-dd': stringDate => {
    const [year, month, day] = stringDate.split('-');
    const monthIndex = Number(month) - 1;
    return new Date(year, monthIndex, day);
  },
};

export default function stringToDate(string, format = 'dd-mmm-yy') {
  const dateFormatParser = dateFormatParsers[format];
  if (!string || !dateFormatParser) return null;

  return dateFormatParser(string);
}

import BadgeRecommended from './BadgeRecommended';
import { selectIcon } from '../templates/place';
import { Fragment } from 'preact';

const RouteOption = ({
  showRecommendedIcon,
  showRecommendedBadge,
  route,
  onSelect,
  showOrigin,
}) => {
  const { origin, destination } = route;

  const handleOnSelect = () => {
    const { origin, destination } = route;

    if (!origin && destination) {
      route.field = 'destination';
    }
    onSelect(route);
  };

  const originText = origin?.cityName ? (
    <Fragment>
      {origin?.cityName} <span>{origin?.display}</span>
    </Fragment>
  ) : (
    <Fragment>{origin?.name}</Fragment>
  );

  const destinationText = destination.cityName ? (
    <Fragment>
      {destination?.cityName} <span>{destination?.display}</span>
    </Fragment>
  ) : (
    <Fragment>{destination?.name}</Fragment>
  );

  const icons = icon => {
    return (
      <div
        className="route-icon"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: selectIcon(icon, false),
        }}
      />
    );
  };

  return (
    <div className="route-option" onClick={handleOnSelect}>
      <div className="route-option-wrapper">
        {icons(showRecommendedIcon ? 'sparkle' : 'recent')}

        <div className="route-option-route">
          {showOrigin && <p className="route-option-terminal">{originText}</p>}
          <p className="route-option-terminal">{destinationText}</p>
        </div>
      </div>

      {showRecommendedBadge && <BadgeRecommended />}
    </div>
  );
};

export default RouteOption;

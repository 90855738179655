import reservamosDevice from './reservamosDevice';
import getProductType from './getProductType';

const hasSentSearchEventKey = 'has-sent-search-event';
const hasSentTicketTypeEventKey = 'has-sent-ticket-type';

const MixpanelTracker = () => {
  const setUpMixPanelSuperProps = () => {
    const deviceFingerprint = reservamosDevice.getFingerprint();
    const mixpanelSuperProps = {
      product: getProductType(),
      ...(deviceFingerprint && { 'Device Fingerprint': deviceFingerprint }),
    };
    window.mixpanel.register(mixpanelSuperProps);
  };

  // The product prop is set in the properties payload in the events
  if (window && window.mixpanel) {
    setUpMixPanelSuperProps();
  }

  const trackEvent = (eventName, properties) => {
    if (window && window.mixpanel) {
      window.mixpanel.track(eventName, properties);
    }
  };

  const trackInterestInSearchEvent = properties => {
    const hasSentSearchEventWithSection = Boolean(
      sessionStorage.getItem(`${hasSentSearchEventKey}-${properties.section}`)
    );
    if (!hasSentSearchEventWithSection) {
      trackEvent('Interest in Search', properties);
      sessionStorage.setItem(`${hasSentSearchEventKey}-${properties.section}`, 'true');
    }
  };

  const trackInterestInTicketTypeEvent = properties => {
    const hasSentTicketTypeEventWithSection = Boolean(
      sessionStorage.getItem(`${hasSentTicketTypeEventKey}-${properties.section}`)
    );
    if (!hasSentTicketTypeEventWithSection) {
      trackEvent('Interest in Ticket Type', properties);
      sessionStorage.setItem(`${hasSentTicketTypeEventKey}-${properties.section}`, 'true');
    }
  };

  return {
    trackInterestInSearchEvent,
    trackInterestInTicketTypeEvent,
    trackEvent,
  };
};

const mixpanelTracker = MixpanelTracker();

export default mixpanelTracker;

const Loading = () => {
  return (
    <div className="rsui-loading-dots">
      <div />
      <div />
      <div />
    </div>
  );
};

export default Loading;

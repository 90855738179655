export default function searchFromQuery () {
  const searchParams = new URLSearchParams(window.location.search);

  return {
    origin: searchParams.get('origin'),
    destination: searchParams.get('destination'),
    departureDate: searchParams.get('departureDate'),
    returnDate: searchParams.get('returnDate'),
    passengers: searchParams.get('passengers'),
  };
}

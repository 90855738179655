import { useEffect, useRef, useState } from 'preact/hooks';
import { IntlProvider, Text } from 'preact-i18n';
import translations from '../translations/i18n';
import 'script-loader!item-quantity-dropdown/lib/item-quantity-dropdown.min';
import useWidgetConfig from '../context/WidgetConfig';
import { getPassengersTypes } from '../utils/encodePassengers';
import QuantitySelectorList from './QuantitytSelectorList';
import Modal from './Modal';
import getProductType from '../utils/getProductType';
import { selectIcon } from '../templates/place';
import SeasonPassengersDisabled from './SeasonPassengersDisabled';
import mixpanelTracker from '../utils/mixpanelTracker';

const PassengersSelector = ({
  passengersSelected,
  passengersList,
  seasonPassengersList,
  maxPassengers,
  onUpdatePassengers,
  error,
  onClick,
  onSubmit,
  onResetPassengers,
  autoOpen,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const { i18nLng } = useWidgetConfig();
  const passengersType = getPassengersTypes(i18nLng);
  const containerRef = useRef();
  const autoOpened = useRef();

  function getInitialState() {
    return passengersList.reduce((acc, item) => {
      acc.push({
        ...item,
        value: item.defaultValue || 0,
      });
      return acc;
    }, []);
  }

  const validateIndependentCategory = ({ passengersCount, passenger, isDecrement, newValue }) => {
    const independents = passengersCount.reduce((acc, item) => {
      if (passengersType[item.name].isIndependent) {
        acc += item.value;
      }
      return acc;
    }, 0);

    const dependents = passengersCount.reduce((acc, item) => {
      if (!passengersType[item.name].isIndependent) {
        acc += item.value;
      }
      return acc;
    }, 0);

    if (isDecrement) {
      // Independents categories are need per pets
      const petFriendlyValue = passengersCount.find(item => item.name === 'pet_friendly')?.value;
      if (passengersType[passenger].isIndependent) {
        if (petFriendlyValue && petFriendlyValue > 0) return independents > petFriendlyValue;
        if (dependents > 0) return independents > 1 && dependents;
      }
    }

    if (passenger === 'pet_friendly') return independents >= newValue;

    if (!passengersType[passenger].isIndependent) {
      return independents > 0;
    }
    return true;
  };

  const handleOnChangeBeforeChange = ({ name, isIncrement, items, newValue }) => {
    return validateIndependentCategory({
      passengersCount: items,
      passenger: name,
      isDecrement: !isIncrement,
      newValue,
    });
  };

  const handleOnChange = ({ name, value }) => {
    onUpdatePassengers(name, value);
    mixpanelTracker.trackEvent('Passengers Selector Modified', { category: name, value });
  };

  const handleOnClick = () => {
    setShowDropdown(true);
    onClick();
    if (!showDropdown) mixpanelTracker.trackEvent('Passengers Selector Opened');
  };

  const handleOnClose = () => {
    setShowDropdown(false);
  };

  const handleClickOutside = event => {
    // If it is a mobile screen the options are not hidden when the user clicks outside the container
    const isMobile = getProductType() === 'web-mobile';
    if (!isMobile && containerRef && !containerRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  const handleOnReset = () => {
    const initialState = getInitialState();
    onResetPassengers({ passengers: initialState });
  };

  const handleOnSubmit = () => {
    mixpanelTracker.trackEvent('Passengers Search Clicked');
    setShowDropdown(false);
    onSubmit();
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Auto open dropdown if autoOpen is true
  useEffect(() => {
    if (autoOpen && !autoOpened.current) {
      setShowDropdown(true);
      autoOpened.current = true;
    }
  }, [autoOpen]);

  const passengersValues = passengersList.reduce((acc, item) => {
    acc.push({
      ...item,
      value: passengersSelected[item.name],
    });
    return acc;
  }, []);

  const passengersCount = passengersValues.reduce((acc, passenger) => {
    acc += passenger.value || 0;
    return acc;
  }, 0);

  const passengersTextSelector = passengersCount > 1 ? 'selector.passengers' : 'selector.passenger';

  return (
    <IntlProvider definition={translations[i18nLng]}>
      <div
        className={`iqdropdown ${error ? 'error' : ''}`}
        onClick={handleOnClick}
        ref={containerRef}
      >
        <label className="label label-passenger">
          <Text id="label.who_is_traveling" />
        </label>
        <p className="iqdropdown-selection">
          {passengersCount} <Text id={passengersTextSelector} />
        </p>
        {showDropdown && (
          <Modal isReduceWidth isFitContent sizeGap="s" onClose={handleOnClose}>
            <QuantitySelectorList
              items={passengersValues}
              error={error}
              beforeDecrement={handleOnChangeBeforeChange}
              beforeIncrement={handleOnChangeBeforeChange}
              maxTotalItems={maxPassengers}
              minTotalItems={1}
              onChange={handleOnChange}
              onSubmit={onSubmit}
            />
            <SeasonPassengersDisabled passengers={seasonPassengersList} />
            <div className="passengers-selector-buttons-footer">
              <button className="reset-passengers-button" onClick={handleOnReset}>
                <Text id="button.clean" />
              </button>
              <button className="search-button" onClick={handleOnSubmit}>
                <div
                  className="search-icon"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: selectIcon('searchButton', false),
                  }}
                />
                <Text id="button.search" />
              </button>
            </div>
          </Modal>
        )}
      </div>
    </IntlProvider>
  );
};

export default PassengersSelector;

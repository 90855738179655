import { Text } from 'preact-i18n';
import Loading from '../Loading';
import RecentTrip from './RecentTrip';
import SelectElement from './SelectElement';
import { NotFoundComponent } from '../../templates/not-found';
import { Fragment } from 'preact';

const SelectContainer = ({ field, options, recentTrips, setRecentTrips, showItems, optionsLoaded, optionsRef, flatVariant, nearestTerminal }) => {
  const showRecentTrips = recentTrips?.length > 0 && showItems && optionsLoaded;
  const showOptions = options.length > 0 && showItems;
  const hasGroup = showOptions && options[0].group;
  return (
    <ul
      className="es-list"
      ref={optionsRef}
      style={{
        display: showItems ? 'block' : 'none',
        paddingLeft: '0px',
        marginTop: '0px',
      }}
    >
      {!optionsLoaded && <Loading />}
      {showRecentTrips && <span className="li-title"><Text id={`title.recent_searches`} /></span>}
      {showRecentTrips && (
          recentTrips.map((option, index) => {
            return (
              <RecentTrip
                key={index}
                setRecentTrips={setRecentTrips}
                origin={option.origin}
                destination={option.destination}
              />
            )})
      )}
      {!hasGroup && showOptions && showRecentTrips && <span className="li-title"><Text id={`title.popular_destinations`} /></span>}

      {showOptions &&
        options.map((option, index) => {
          const hasGroup = option.group;
          const isNearest = Boolean(nearestTerminal && field === 'origin' && option?.id === nearestTerminal?.id);
          return hasGroup ? (
            <Fragment key={option.group}>
              <span className="li-title">
                {option.group !== 'other' ? option.group : <Text id={`title.other`} />}
              </span>
              {option.options.map((subOption, index) => {
                return (
                  <SelectElement
                    key={subOption.id}
                    place={subOption}
                    position={index + 1}
                    flatVariant={flatVariant}
                    showNearest={isNearest}
                    isGrouped
                  />
                )
              })}
            </Fragment>
        ) : (
            <SelectElement
              key={option.id}
              place={option}
              position={index + 1}
              flatVariant={flatVariant}
              showNearest={isNearest}
            />
        )})}
      {!showOptions && optionsLoaded && <NotFoundComponent noResultsLabel={<Text id='notification.no_results' />} />}
    </ul>
  );
};

export default SelectContainer;

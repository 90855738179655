import { useEffect, useRef } from 'preact/compat';
import { Text } from 'preact-i18n';
import { useState } from 'preact/hooks';
import { camelizeKeys, decamelizeKeys } from 'humps';
import createUrl from '../../utils/createUrl';
import normalize from '../../utils/normalize';
import mixpanelTracker from '../../utils/mixpanelTracker';
import SelectContext from './context';
import { formatDisplayType } from './displayTypes';
import { fetchPlace } from '../../utils/fetchPlace';
import SearchInput from '../SearchInput';
import getProductType from '../../utils/getProductType';
import { PlacesProvider } from '../../context/Places';

require('../../styles/AutocompleteSelect.min.css');

const AutocompleteSelect = ({
  field,
  error,
  hasArrow,
  placeholder,
  label,
  sourceUrl,
  displayType,
  isPackagesSearch,
  onChange,
  onInputChange,
  onNoSearchResults,
  place: placeProp,
  from,
  to,
  initialPlaceSlug,
  orderDestinationHit,
  coordsToOrder,
  allowFetch,
  flatVariant,
  originHasInitialSlug,
  showNearestTerminal,
  nearestTerminal,
  onFocus,
  didInitialOriginChanged,
  groupPlaces,
  disabled = false,
}) => {
  const [input, setInput] = useState('');
  const [places, setPlaces] = useState([]);
  const [options, setOptions] = useState([]);
  const [optionsLoaded, setOptionsLoaded] = useState(false);
  const [disabledInput, setDisabledInput] = useState(disabled);
  const [showItems, setShowItems] = useState(false);
  const [originsLoaded, setOriginsLoaded] = useState(false);
  const orderByGeoFetched = useRef(false);
  const containerRef = useRef(null);
  const inputRef = useRef({});
  const optionsRef = useRef(null);
  const debounceRef = useRef(null);
  const showingInitialSlug = useRef(false);

  /**
   * It checks which input ref should be used based on the product type (web or web-mobile).
   * @returns String - The current input ref based on the product type
   */
  const currentInputRef = () => {
    const isMobile = getProductType() === 'web-mobile';
    return isMobile ? inputRef.current?.mobile : inputRef.current?.desktop;
  };

  const handleClickOutside = event => {
    // If it is a mobile screen the options are not hidden when the user clicks outside the container
    const isMobile = getProductType() === 'web-mobile';
    if (!isMobile && containerRef && !containerRef.current.contains(event.target)) {
      setShowItems(false);
    }
  };

  const handleDisableField = state => {
    if (disabled) {
      setDisabledInput(true);
      return;
    }

    setDisabledInput(state);
  };

  const placesUrl = () => {
    const url = createUrl(sourceUrl);
    const { lat, long } = coordsToOrder || {};

    if (from) {
      url.setQueryParam('from', from);
      if (orderDestinationHit) url.setQueryParam('order_by', 'origin_hits');
    } else if (to) {
      url.setQueryParam('to', to);
      if (orderDestinationHit) url.setQueryParam('order_by', 'destination_hits');
    } else {
      url.setQueryParam('prefetch', 'true');
      if (orderDestinationHit) url.setQueryParam('order_by', 'origin_hits');
    }
    if (lat && long && coordsToOrder && field === 'origin')
      url.setQueryParam('lat', lat).setQueryParam('long', long);

    return url.href;
  };

  const fetchTerminals = (showAfterFetch = true, filterValue) => {
    setOptionsLoaded(false);

    if (showAfterFetch) {
      setShowItems(true);
    }
    /**
     * After fetching terminals, it's not necessary to check if the initial slug
     * is being set up, to be able to open the destination list if the user change the origin
     */
    if (showingInitialSlug.current) showingInitialSlug.current = false;

    fetch(placesUrl())
      .then(response => response.json())
      .then(data => {
        setOptionsLoaded(true);
        setOriginsLoaded(true);
        setOptions(data);
        setPlaces(data);
        if (filterValue) filterOptions(filterValue);
      })
      .then(() => {
        if (showAfterFetch) {
          optionsRef.current?.scrollTo(null, 0);
          if (getProductType() === 'web') currentInputRef()?.focus();
        }
      });
  };

  const fetchTerminalBy = slug => {
    setOptionsLoaded(false);
    fetch(placesUrl())
      .then(response => response.json())
      .then(data => {
        setOriginsLoaded(true);
        setOptionsLoaded(true);
        setOptions([...data]);
        setPlaces([...data]);

        if (data && data.length > 0) {
          const place = data.find(p => {
            return p.slug === slug;
          });
          if (place) {
            onChange(field, camelizeKeys(place));
            setInput(formatDisplayType(displayType, place, isPackagesSearch));
          }
        }
      });
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Sets the initial place in the input and also it is selected for the search
   */
  const setInitialSlug = () => {
    if (!initialPlaceSlug) return;
    fetchTerminalBy(initialPlaceSlug);
  };

  useEffect(() => {
    setInitialSlug();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialPlaceSlug]);

  useEffect(() => {
    if (placeProp?.slug && placeProp?.id) {
      setInput(formatDisplayType(displayType, decamelizeKeys(placeProp), isPackagesSearch));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placeProp]);

  useEffect(() => {
    setDisabledInput(disabled);
  }, [disabled]);

  /**
   * The function `groupOptionsBy` groups an array of options by a specified property and returns an
   * array of objects with the grouped options.
   * @param {Object} options - An array of options to be grouped.
   * @param {string} groupBy - The property to group the options by.
   * @returns The function `groupOptionsBy` returns an array of objects. Each object in the array has
   * two properties: `group` and `options`. The `group` property represents the group name, and the
   * `options` property represents an array of options belonging to that group.
   */
  const groupOptionsBy = ({ options, groupBy }) => {
    if (!options.length) return [];
    const optionsReduced = options.reduce((acc, place) => {
      const key = place[groupBy] || 'other';
      if (!acc[key]) {
        acc[key] = { group: key, options: [] };
      }
      acc[key].options.push(place);
      return acc;
    }, {});

    return Object.values(optionsReduced);
  };

  const handleElementSelect = () => {
    setShowItems(false);
  };

  const getPlaceDisplayText = place => {
    let displayText = '';

    if (displayType === 'city') {
      const cityDisplay = isPackagesSearch ? '' : `, ${place.display}`;
      displayText = `${place.city_name}${cityDisplay}`;
    } else if (displayType === 'city_state') {
      displayText = `${place.city_name}, ${place.state} ${place.display}`;
    } else {
      displayText = `${place.display}, ${place.state}`;
    }

    return normalize(displayText);
  };

  const queriedUrlFetch = query => {
    const { lat, long } = coordsToOrder || {};

    const url = createUrl(sourceUrl);

    if (query) {
      url.setQueryParam('q', query);
    }

    if (from) {
      url.setQueryParam('from', from);
    } else if (to) {
      url.setQueryParam('to', to);
    }

    if (lat && long && coordsToOrder) url.setQueryParam('lat', lat).setQueryParam('long', long);

    return url.href;
  };

  const getPlacesToUse = places => {
    let placesToUse = [...places];
    const nearestTerminalIndex = places.findIndex(place => place.id === nearestTerminal?.id);
    const nearestTerminalFound = places[nearestTerminalIndex];
    if (nearestTerminalFound && field === 'origin') {
      placesToUse.splice(nearestTerminalIndex, 1);
      placesToUse.unshift(nearestTerminalFound);
    }
    return placesToUse;
  };

  const setPlacesOrFetch = (value, places) => {
    if (places.length > 0) {
      setOptions(getPlacesToUse(places));
      return;
    }
    if (allowFetch) {
      clearTimeout(debounceRef.current);
      debounceRef.current = setTimeout(() => {
        setOptionsLoaded(false);
        fetchPlace(queriedUrlFetch(value))
          .then(response => {
            setOptions(getPlacesToUse(response));
          })
          .finally(() => {
            setOptionsLoaded(true);
          });
      }, 500);
    } else {
      setOptions([]);
    }
  };

  const filterOptions = value => {
    switch (displayType) {
      case 'state':
      case 'city': {
        // eslint-disable-next-line no-case-declarations
        const filteredPlaces = places.filter(place => getPlaceDisplayText(place).includes(value));
        setPlacesOrFetch(value, filteredPlaces);
        break;
      }
      case 'city_state': {
        // eslint-disable-next-line no-case-declarations
        const inputValue = value.split(' ');
        const filteredPlaces = places.filter(place =>
          inputValue.every(el => getPlaceDisplayText(place).includes(el))
        );
        setPlacesOrFetch(inputValue, filteredPlaces);
        break;
      }

      default: {
        const filteredPlaces = places.filter(place => getPlaceDisplayText(place).includes(value));
        setPlacesOrFetch(value, filteredPlaces);
        break;
      }
    }
  };

  useEffect(() => {
    clearTimeout(debounceRef.current);
    if (optionsLoaded && !options.length) {
      debounceRef.current = setTimeout(() => {
        onNoSearchResults(field, input);
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, optionsLoaded]);

  useEffect(() => {
    if (field === 'destination') {
      const shouldOpenList =
        (!originHasInitialSlug || didInitialOriginChanged()) &&
        !placeProp?.slug &&
        (!showingInitialSlug.current || didInitialOriginChanged());
      // Remove from destination if selected origin is equal to destination
      if (placeProp && placeProp.slug === from) {
        setInput('');
        onChange(field, {});
      }
      if (!from) {
        handleDisableField(true);
        if (!disabledInput) {
          setInput('');
          onChange(field, {});
        }
      } else {
        handleDisableField(false);
        fetchTerminals(shouldOpenList);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from]);

  const originRequest = () => {
    if (field === 'origin' && originsLoaded) {
      // Remove from origin if selected destination is equal to origin
      if (placeProp && placeProp.slug === to) {
        setInput('');
        onChange(field, {});
      }
      fetchTerminals(!placeProp.slug && !showingInitialSlug.current);
    }
  };

  useEffect(() => {
    originRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [to]);

  // If the order by geolocation was not enabled before, it is fetched the places again order by geolocation
  useEffect(() => {
    if (coordsToOrder && !orderByGeoFetched.current) {
      const valueToFilter = currentInputRef()?.value && normalize(currentInputRef()?.value);
      fetchTerminals(false, valueToFilter);
      orderByGeoFetched.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coordsToOrder]);

  const handleInputChange = event => {
    showingInitialSlug.current = false;
    const newInput = normalize(event.target.value);
    setInput(newInput);
    filterOptions(newInput.trim());
    if (newInput === '') {
      onChange(field, {});
    }
    if (onInputChange && typeof onInputChange === 'function') {
      onInputChange(field, newInput);
    }
  };

  const handleInputClick = () => {
    const transformFieldToEvent = {
      origin: 'Origin',
      destination: 'Destination',
    };
    mixpanelTracker.trackInterestInSearchEvent({
      section: transformFieldToEvent[field] || field,
    });
    if ((field === 'origin' || field === 'place') && !originsLoaded) {
      fetchTerminals();
    }

    if (originsLoaded && !showItems) {
      setShowItems(true);
    }
  };

  const handleOnClean = () => {
    if (field === 'origin') {
      setShowItems(true);
      currentInputRef()?.focus();
    }
    setInput('');
    onChange(field, {});
  };

  const handleOnCloseModal = () => {
    setShowItems(false);
  };

  /**
   *
   * @param {Object} params - object with the params for function
   * @param {String} params.field - Field that sends the place or places selected
   * @param {Object} params.origin - Origin selected
   * @param {Object} params.destination - Destination selected
   */
  const handleOnSelectRecommended = ({ field, origin, destination }) => {
    if (!origin && !destination) {
      throw new Error('You must provide an origin or destination');
    }
    let placeObject = {};
    /**
     * If origin and destination are in the params, an object with both fields
     * is created because a full route was selected.
     * If there is just one field, the place object is just selected.
     */
    if (origin && destination) {
      placeObject.origin = camelizeKeys(origin);
      placeObject.destination = camelizeKeys(destination);
    } else {
      placeObject = camelizeKeys({ origin, destination }[field]);
    }
    onChange(field, placeObject);
    handleElementSelect();
  };

  const optionsToShow = groupPlaces ? groupOptionsBy({ options, groupBy: 'state' }) : options;

  return (
    <SelectContext.Provider
      value={{
        displayType,
        isPackagesSearch,
        onChange,
        field,
        handleElementSelect,
      }}
    >
      <PlacesProvider places={places} type={field} selectedOrigin={from} selectedDestination={to}>
        <SearchInput
          modalTitle={<Text id={`title.select_your_${field}`} />}
          field={field}
          error={error}
          hasArrow={hasArrow}
          containerRef={containerRef}
          label={label}
          placeholder={placeholder}
          disabledInput={disabledInput}
          handleOnChange={handleInputChange}
          handleOnFocus={onFocus}
          handleInputClick={handleInputClick}
          handleOnClean={handleOnClean}
          handleOnCloseModal={handleOnCloseModal}
          value={input}
          inputRef={inputRef}
          showItems={showItems}
          options={optionsToShow}
          optionsLoaded={optionsLoaded}
          optionsRef={optionsRef}
          flatVariant={flatVariant}
          nearestTerminal={showNearestTerminal && nearestTerminal}
          handleOnSelectRecommended={handleOnSelectRecommended}
          validPlace={Boolean(placeProp?.slug)}
        />
      </PlacesProvider>
    </SelectContext.Provider>
  );
};

export default AutocompleteSelect;

import { decamelizeKeys } from 'humps';

export function saveRecentSearches(origin, destination, maxRecentSearches, recentSearchesLS) {
  const recentSearches = getRecentSearches(maxRecentSearches, recentSearchesLS);
  const originExists = recentSearches.find(search => search.origin.id === origin.id);
  const destinationExists = recentSearches.find(search => search.destination.id === destination.id);
  if (originExists && destinationExists) return;
  if (recentSearches.length >= maxRecentSearches) recentSearches.pop();

  // Deleting extra props
  delete origin.preferred;
  delete destination.preferred;
  delete destination.isPopularDestination;

  recentSearches.unshift({
    origin: decamelizeKeys(origin),
    destination: decamelizeKeys(destination),
  });
  localStorage.setItem(recentSearchesLS, JSON.stringify(recentSearches));
}

export function getRecentSearches(maxRecentSearches, recentSearchesLS) {
  const recentSearches = JSON.parse(localStorage.getItem(recentSearchesLS));
  return recentSearches?.splice(0, maxRecentSearches) || [];
}

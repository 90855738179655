import http from '../http';

const PROFILES_ENDPOINT = 'profiles';

const UserPreferencesService = () => {
  // Get the user's identifiers for tracking, profiles, etc.
  const getUserIdentifiers = () => {
    const userIdentifiers = {};
    userIdentifiers.distinct_id = window.mixpanel?.get_distinct_id();
    return userIdentifiers;
  };

  const pollingUserProfile = async ({ url, response }) => {
    return new Promise((resolve, reject) => {
      const { pollingId, state } = response;
      if (state === 'finished') {
        resolve(response);
        return;
      }

      http
        .pollEndpoint({ url: `${url}/${pollingId}` })
        .then(profile => resolve(profile))
        .catch(error => reject(error));
    });
  };

  /**
   * Get the user's profile from the API using the identifiers,
   * and set the state with the response. The data contains customization
   * info for the user.
   * @param {Object} options - The options for the request
   * @param {String} options.profileUrl - The API source
   */
  const getUserProfile = async ({ profileUrl }) => {
    const userIDs = getUserIdentifiers();

    return new Promise((resolve, reject) => {
      if (!userIDs.distinct_id) {
        const msg = 'No identifiers found';
        reject(msg);
        return;
      }

      const url = `${profileUrl}/${PROFILES_ENDPOINT}`;
      http
        .post({
          url,
          payload: userIDs,
        })
        .then(async response => {
          if (response.error) return reject(response);
          const profile = await pollingUserProfile({ url, response });
          resolve(profile);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  function isCacheOld() {
    const cache = getCache();
    const { timestamp } = cache;
    const currentTime = new Date().getTime();
    const timeDifference = currentTime - timestamp;
    const threeDaysInMilliseconds = 259200000;
    if (timeDifference > threeDaysInMilliseconds) {
      removeCache();
      return true;
    }
  }

  function getCache() {
    const cache = JSON.parse(localStorage.getItem('userPreferences'));
    return cache;
  }

  function removeCache() {
    localStorage.removeItem('userPreferences');
  }

  function saveCache(cache) {
    localStorage.setItem('userPreferences', JSON.stringify(cache));
  }

  return {
    getUserProfile,
    isCacheOld,
    removeCache,
    getCache,
    saveCache,
  };
};

const userPreferencesService = UserPreferencesService();
export default userPreferencesService;

import useRecommendedRoutes from '../../context/RecommendedRoutes';
import { selectIcon } from '../../templates/place';
import BadgeRecommended from '../BadgeRecommended';
import RecommendedRoutesList from './RecommentedRoutesList';

const RecommendedRoutes = ({
  onSelectRoute,
  showRecommendedIcon,
  showRecommendedBadge,
  title,
  showPreferredRoutes,
  showRecentSearches,
  showPopularDestinations,
}) => {
  const { exists, recommendedRoutes } = useRecommendedRoutes({
    showPreferredRoutes,
    showRecentSearches,
    showPopularDestinations,
  });

  if (!exists) return null;

  return (
    <div className="recommended-routes">
      <div className="recommended-routes-header">
        <div className="recommended-routes-header-content">
          {showRecommendedIcon && (
            <div
              className="icon"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: selectIcon('fire', false),
              }}
            />
          )}

          <p className="recommended-routes-title">{title}</p>
        </div>

        {showRecommendedBadge && <BadgeRecommended />}
      </div>

      <RecommendedRoutesList onSelect={onSelectRoute} routesGroups={recommendedRoutes} />
    </div>
  );
};

export default RecommendedRoutes;

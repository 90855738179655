import { useContext } from 'preact/hooks';
import PlacesContext from './PlacesContext';

const usePlaces = () => {
  const { places, type } = useContext(PlacesContext);
  if (!places || !type) {
    throw new Error('usePlaces must be used within a PlacesProvider');
  }
  return { places, type };
};

export default usePlaces;

import { h } from 'preact';
import { useState } from 'preact/hooks';
import SelectContext from './context';
import titleize from '../../utils/titleize';
import { selectIcon } from '../../templates/place';
import { formatCityDisplay } from './displayTypes';

import { camelizeKeys } from 'humps';

export default function RecentTrip({ origin, destination }) {
  const [hover, setHover] = useState(false);

  const cityDisplayType = isPackagesSearch => (
    <div>
      <p className="place-info">
        {formatCityDisplay(isPackagesSearch, origin)}
      </p>
      <p className="place-info">
        {formatCityDisplay(isPackagesSearch, destination)}
      </p>
    </div>
  );

  const stateDisplayType = () => (
    <>
      <div
        className="autocomplete-icon"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: selectIcon('recent', false),
        }}
      />
      <div>
        <p className="place-info">
          <b>{titleize(origin.display)}</b>, {titleize(origin.state)}
        </p>
        <p className="place-info">
          <b>{titleize(destination.display)}</b>, {titleize(destination.state)}
        </p>
      </div>
    </>
  );

  const cityStateDisplayType = () => (
    <>
      <div
        className="autocomplete-icon"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: selectIcon('recent', false),
        }}
      />
      <div>
        <p className="place-info">
          <b>{titleize(origin.city_name)}</b>, {`${titleize(origin.state)} ${titleize(origin.display)}`}
        </p>
        <p className="place-info">
          <b>{titleize(destination.city_name)}</b>, {`${titleize(destination.state)} ${titleize(destination.display)}`}
        </p>
      </div>
    </>
  );

  const renderDisplayType = (displayType, isPackagesSearch) => {
    switch (displayType) {
      case 'city':
        return cityDisplayType(isPackagesSearch);

      case 'state':
        return stateDisplayType();

      case 'city_state':
        return cityStateDisplayType();

      default:
        return stateDisplayType();
    }
  };

  const handleOnClick = (onChange, handleElementSelect) => {
    onChange('recentTrip', {
      origin: camelizeKeys(origin),
      destination: camelizeKeys(destination),
    });
    handleElementSelect();
  }

  return (
    <SelectContext.Consumer>
    {value => {
      return (
        <li
          className={`es-visible ${hover ? 'selected' : ''}`}
          style="display: flex;"
          onClick={() => handleOnClick(value.onChange, value.handleElementSelect)}
          onMouseOver={() => setHover(true)}
          onFocus={() => setHover(true)}
          onMouseOut={() => setHover(false)}
          onBlur={() => setHover(false)}
        >
          {renderDisplayType(value.displayType, value.isPackagesSearch)}
        </li>
      )
    }}
    </SelectContext.Consumer>
  );
};
const PaymentMethods = ({ cardsAvailable, oxxoAvailable, paypalAvailable, coppelpayAvailable }) => {
  return (
    <div className="payment-methods">
      {cardsAvailable && (
        <div className="payment-methods-item">
          <div className="cards" />
        </div>
      )}
      {oxxoAvailable && (
        <div className="payment-methods-item">
          <div className="oxxo" />
        </div>
      )}
      {paypalAvailable && (
        <div className="payment-methods-item">
          <div className="paypal" />
        </div>
      )}
      {coppelpayAvailable && (
        <div className="payment-methods-item">
          <div className="coppelpay" />
        </div>
      )}
    </div>
  );
};

export default PaymentMethods;

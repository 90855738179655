import { Text } from 'preact-i18n';

const SeasonPassenger = ({ title, description }) => (
  <div className="sw-passenger-category">
    <div className="sw-passenger-category-info">
      <p className={`sw-title ${'sw-title-disabled'}`}>{title}</p>
      {description && (
        <p className={`sw-description ${'sw-description-disabled'}`}>{description}</p>
      )}
    </div>

    <p className="sw-chip-disabled">{<Text id="text.disabled" />}</p>
  </div>
);

const SeasonPassengersDisabled = ({ passengers }) => {
  if (!passengers.length) return null;
  return (
    <div className="sw-season-passengers-disabled">
      <div className="sw-season-passengers-disabled-badge">
        <p className="sw-season-passengers-disabled-badge-title">
          <Text id="text.season" />
        </p>
      </div>

      <div className="sw-season-passengers-disabled-category">
        {passengers.map((passenger, index) => (
          <div key={index}>
            <SeasonPassenger title={passenger.title} description={passenger.description} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SeasonPassengersDisabled;

/* eslint-disable import/prefer-default-export */
import { ClientJS } from 'clientjs';

/**
 * Manage the device identifiers
 * @returns {Object} The device identifiers
 */
const ReservamosDevice = () => {
  const client = new ClientJS();

  /**
   * Returns the device fingerprint
   * @returns {String} The device fingerprint
   */
  const getFingerprint = () => {
    let deviceFingerprint = localStorage.getItem('device_fingerprint');
    if (deviceFingerprint) return deviceFingerprint;

    deviceFingerprint = `${client.getFingerprint()}`;
    localStorage.setItem('device_fingerprint', deviceFingerprint);
    return deviceFingerprint;
  };

  return {
    getFingerprint,
  };
};

const reservamosDevice = ReservamosDevice();

export default reservamosDevice;

import { Text } from 'preact-i18n';
import whatsapp from "../../assets/images/whatsapp-cta/whatsapp-icon.svg";
import mixpanelTracker from "../utils/mixpanelTracker";

const WhatsappCta = ({ whatsappUrl, whatsappCopy }) => {

  if (!whatsappUrl) return null; 
  const handleOnClick = () => {
    mixpanelTracker.trackEvent('Search Whatsapp CTA Clicked');
  }
  return (
    <a className="whatsapp-bubble" onClick={handleOnClick} href={whatsappUrl} target="_blank" rel="noreferrer">
      <span className="whatsapp-icon">
        <img className="whatsapp-logo" src={whatsapp} alt="whatsapp-logo" />
      </span>
      <span className="whatsapp-message">
        <Text id={`text.${whatsappCopy}`} />
      </span>
    </a>
  );
};

export default WhatsappCta;

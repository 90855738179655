import { Component } from 'preact';
import { Text, withText } from 'preact-i18n';
import createUrl from '../utils/createUrl';
import resultsUrl from '../utils/resultsUrl';
import AutocompleteSelect from './AutoCompleteSelect';

class PackagesSearch extends Component {
  state = {
    place: { slug: '' },
    hasError: false,
  };

  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit() {
    const { place } = this.state;
    const { config } = this.props;

    if (!place.slug) {
      this.setState({ hasError: true });
      return;
    }

    const url = resultsUrl(this.state, config, 'packages');
    window.location.href = url;
  }

  render() {
    const { place, hasError } = this.state;
    const { config, button_search, text_select_a_city } = this.props;
    const { packagesUrl } = config;

    const protocol = /https?:/.test(window.location.protocol) ? 'https:' : 'http:';
    const autocompleteUrl = createUrl(`${protocol}//${packagesUrl}/destinations`);

    return (
      <form className="reservamos-search" onSubmit={e => e.preventDefault()}>
        <div className="search-label">
          <p className="search-label-text">{text_select_a_city}</p>
          <div className="search-form">
            <div className="form-field">
              <AutocompleteSelect
                field="place"
                sourceUrl={autocompleteUrl.href}
                placeholder="destination"
                place={place}
                error={hasError}
                onChange={(_, newPlace) => this.setState({ place: newPlace })}
                isPackagesSearch
                hasArrow
              />
            </div>
            <div className="button-wrapper">
              <button type="submit" onClick={this.onSubmit} className="search-button">
                {button_search}
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default withText(() => ({
  text_select_a_city: <Text id="text.select_a_city" />,
  button_search: <Text id="button.search" />,
}))(PackagesSearch);

import { Localizer, Text } from 'preact-i18n';
import Modal from './Modal';
import SelectContainer from './AutoCompleteSelect/SelectContainer';
import { selectIcon } from '../templates/place';
import { useEffect, useState } from 'preact/hooks';
import RecommendedRoutes from './RecommendedRoutes';
import { Fragment } from 'preact';
import getProductType from '../utils/getProductType';
import useWidgetConfig from '../context/WidgetConfig';

/**
 * Input component for handling user input.
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.fieldName - The field identifier.
 * @param {string} props.label - The label for the input.
 * @param {string} props.placeholder - Placeholder for the input field.
 * @param {boolean} props.disabled - Flag indicating whether the input is disabled.
 * @param {Function} props.handleOnClick - Function to handle input click.
 * @param {Function} props.handleOnChange - Function to handle input change.
 * @param {Function} props.handleOnFocus - Function to handle input focus.
 * @param {Function} props.handleOnClean - Function to handle cleaning the input.
 * @param {string} props.value - The value of the input field.
 * @param {Object} props.inputRef - Ref for the input element.
 * @param {boolean} props.isMobile - Flag indicating whether the component is for mobile.
 * @param {boolean} props.showSearchIcon - Flag indicating whether to show the search icon.
 * @param {Boolean} props.validPlace - Indicates if a valid places has been selected.
 * @returns {JSX.Element} Input component JSX element.
 */
const Input = ({
  fieldName,
  label,
  placeholder,
  disabled,
  handleOnClick,
  handleOnChange,
  handleOnFocus,
  handleOnClean,
  value,
  inputRef,
  isMobile,
  showSearchIcon,
  error,
  validPlace,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const inputType = isMobile ? 'mobile' : 'desktop';
  const containerClass =
    (isMobile ? 'sw-modal-input-container' : '') + (isFocused && !isMobile ? ' focused' : '');

  const isMobileView = getProductType() === 'web-mobile';
  const canCleanField = value && !disabled;

  const handleOnFocusInput = () => {
    if (isMobileView && !isMobile) {
      inputRef.current[inputType].style.opacity = 0;
      setTimeout(() => (inputRef.current[inputType].style.opacity = 1), 500);
    }

    setIsFocused(true);
    handleOnFocus();
  };
  const handleOnBlurInput = () => {
    setIsFocused(false);
  };

  const handleOnContainerClick = e => {
    if (disabled) return;
    setIsFocused(true);
    inputRef.current[inputType].focus();
    handleOnClick(e);
  };

  useEffect(() => {
    /**
     * If the input is for mobile and the product is web-mobile, focus the input.
     * This is because the input is hidden in the desktop version.
     */
    if (isMobileView && isMobile && inputRef.current) {
      inputRef.current[inputType]?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const iconType = fieldName === 'origin' ? 'solid' : 'outline';

  const inputElement = (
    <Localizer>
      <div className="input-routes">
        <label
          className={`label label-route ${error ? 'label-error' : ''}`}
          for={`txt${fieldName}-${isMobile ? 'mobile' : 'desktop'}`}
        >
          <Text id={`label.${label}`} />
        </label>

        <div className="input-route">
          <div
            className={`input-route-icon ${iconType} ${validPlace && 'active'}`}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: selectIcon(`pin-${iconType}`, false),
            }}
          />
          <Localizer>
            <input
              id={`txt${fieldName}-${isMobile ? 'mobile' : 'desktop'}`}
              type="text"
              autoComplete="off"
              className={`${fieldName} es-input`}
              placeholder={<Text id={`placeholder.${placeholder}`} />}
              disabled={disabled}
              onClick={handleOnClick}
              onInput={handleOnChange}
              onFocus={handleOnFocusInput}
              onBlur={handleOnBlurInput}
              value={value}
              ref={ref => {
                inputRef.current[inputType] = ref;
              }}
            />
          </Localizer>
        </div>
      </div>
    </Localizer>
  );

  return (
    <div className={`input-container ${containerClass}`} onClick={handleOnContainerClick}>
      <div className="input-wrapper">{inputElement}</div>
      {canCleanField && (
        <div
          className={`clean-place-input ${!isMobile ? 'desktop' : ''}`}
          onClick={handleOnClean}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: selectIcon('cleanIcon', false),
          }}
        />
      )}
      {showSearchIcon && (
        <div
          className="icon"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: selectIcon('search', false),
          }}
        />
      )}
    </div>
  );
};

/**
 * SearchInput component to show in the form, it handles the desktop and mobile version.
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.field - The field identifier.
 * @param {string} props.error - The error message, if any.
 * @param {boolean} props.hasArrow - Flag indicating whether the input has an arrow.
 * @param {Object} props.containerRef - Ref for the container element.
 * @param {string} props.placeholder - Placeholder for the input field.
 * @param {boolean} props.disabledInput - Flag indicating whether the input is disabled.
 * @param {Function} props.handleOnChange - Function to handle input change.
 * @param {Function} props.handleOnFocus - Function to handle input focus.
 * @param {Function} props.handleInputClick - Function to handle input click.
 * @param {Function} props.handleOnClean - Function to handle cleaning the input.
 * @param {Function} props.handleOnCloseModal - Function to handle closing the modal.
 * @param {string} props.value - The value of the input field.
 * @param {Object} props.inputRef - Ref for the input element, it has to be an object to be able to save desktop and mobile input.
 * @param {boolean} props.showItems - Flag indicating whether to show the places items.
 * @param {Array} props.options - Array of options for the search.
 * @param {boolean} props.optionsLoaded - Flag indicating whether options are loaded.
 * @param {Object} props.optionsRef - Ref for the options element.
 * @param {boolean} props.flatVariant - Flag indicating whether to use a flat variant.
 * @param {boolean} props.nearestTerminal - Flag indicating the nearest terminal.
 * @param {Function} props.handleOnSelectRecommended - Function to handle selecting a recommended route.
 * @param {Boolean} props.validPlace - Indicates if a valid places has been selected.
 * @returns {JSX.Element} SearchInput component JSX element.
 */
const SearchInput = ({
  field,
  error,
  hasArrow,
  containerRef,
  label,
  placeholder,
  disabledInput,
  handleOnChange,
  handleOnFocus,
  handleInputClick,
  handleOnClean,
  handleOnCloseModal,
  value,
  inputRef,
  showItems,
  options,
  optionsLoaded,
  optionsRef,
  flatVariant,
  nearestTerminal,
  handleOnSelectRecommended,
  modalTitle,
  validPlace,
}) => {
  const handleOnClick = () => {
    handleInputClick();
  };

  const onCloseModal = e => {
    if (e?.target?.id === 'modalOverlay' || e?.target?.id === 'closeModalButton') {
      handleOnCloseModal();
    }
  };
  const { freezeInitial } = useWidgetConfig();
  const showRecommendedRoutes = !value && !freezeInitial;

  const isDestination = field === 'destination';

  return (
    <div ref={containerRef}>
      <div
        className={`${field}-wrapper ${hasArrow ? 'has-arrow' : ''}`}
        key={`${field}-wrapper`}
        role="presentation"
      >
        <Fragment>
          {error && (
            <div
              className="icon-error"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: selectIcon('error', false),
              }}
            />
          )}
          <Input
            fieldName={field}
            label={label}
            placeholder={placeholder}
            disabled={disabledInput}
            handleOnClick={handleOnClick}
            handleOnChange={handleOnChange}
            handleOnFocus={handleOnFocus}
            handleOnClean={handleOnClean}
            value={value}
            inputRef={inputRef}
            error={error}
            validPlace={validPlace}
          />
        </Fragment>
      </div>

      {showItems && (
        <Modal onClose={onCloseModal} title={modalTitle} showCloseButton>
          <Input
            fieldName={field}
            label={label}
            placeholder={placeholder}
            disabled={disabledInput}
            handleOnClick={handleOnClick}
            handleOnChange={handleOnChange}
            handleOnFocus={handleOnFocus}
            handleOnClean={handleOnClean}
            value={value}
            inputRef={inputRef}
            isMobile
            showSearchIcon
            validPlace={validPlace}
          />
          <Localizer>
            {showRecommendedRoutes && (
              <RecommendedRoutes
                onSelectRoute={handleOnSelectRecommended}
                title={
                  isDestination ? (
                    <Text id={`title.popular_destinations_from_origin`} />
                  ) : (
                    <Text id={`title.select_your_next_travel`} />
                  )
                }
                showRecentSearches={!isDestination}
                showRecommendedIcon={isDestination}
                showRecommendedBadge={isDestination}
                showPopularDestinations={isDestination}
              />
            )}
          </Localizer>
          <SelectContainer
            field={field}
            options={options}
            showItems={showItems}
            optionsLoaded={optionsLoaded}
            optionsRef={optionsRef}
            flatVariant={flatVariant}
            nearestTerminal={nearestTerminal}
          />
        </Modal>
      )}
    </div>
  );
};

export default SearchInput;

import { translate } from 'preact-i18n';
import translations from '../translations/i18n';
/**
 * Receives a passengers object with the type as the key and the quantity
 * as the value and returns a string with the passengers encoded for
 * query params or for the URL
 * ```
 * if type is queryParams:
 *    { adult: 2, child: 1, infant: 1 } => adult=2&child=1&infant=1
 * else:
 *    { adult: 2, child: 1, infant: 1 } => A2-C1-I1
 * ```
 * @method encodePassengers
 * @param {Object} passengers Passengers object
 * @return {String}
 */
export default function encodePassengers(passengers) {
  const passengerTypes = Object.keys(passengers);
  const encoded = [];

  passengerTypes.forEach(passengerType => {
    const quantity = passengers[passengerType];

    if (quantity > 0) {
      encoded.push(`${passengerType.substr(0, 1).toUpperCase()}${quantity}`);
    }
  });

  return encoded.join('-');
}

export const encodeCustomPassengers = passengers => {
  const passengerTypes = Object.keys(passengers);
  const encoded = [];

  passengerTypes.forEach(passengerType => {
    const quantity = passengers[passengerType];

    if (quantity > 0) {
      encoded.push(`${passengerType}-${quantity}`);
    }
  });
  return encoded.join(',');
};

export const getPassengersTypes = lang => {
  const dictionary = translations[lang];
  return {
    adult: {
      name: 'adult',
      defaultValue: 1,
      title: translate('adult', 'label', dictionary),
      description: translate('adult_description', 'text', dictionary, { years_old: 12 }),
      minValue: 0,
      isEnabled: true,
      isIndependent: true,
    },
    general: {
      name: 'general',
      defaultValue: 1,
      title: translate('adult', 'label', dictionary),
      description: translate('adult_description', 'text', dictionary, { years_old: 12 }),
      minValue: 0,
      isEnabled: true,
      isIndependent: true,
    },
    minor: {
      name: 'minor',
      defaultValue: 0,
      title: translate('minor', 'label', dictionary),
      description: translate('minor_description', 'text', dictionary, {
        younger_age: 3,
        older_age: 11,
      }),
      minValue: 0,
      isEnabled: true,
    },
    older: {
      name: 'older',
      defaultValue: 0,
      title: translate('insen', 'label', dictionary),
      description: translate('required_credential', 'text', dictionary),
      minValue: 0,
      isEnabled: true,
      isIndependent: true,
    },
    pet_friendly: {
      name: 'pet_friendly',
      defaultValue: 0,
      title: translate('pet', 'label', dictionary),
      description: translate('pet', 'text', dictionary),
      minValue: 0,
      isEnabled: true,
    },
    infant: {
      name: 'infant',
      defaultValue: 0,
      title: translate('infant', 'label', dictionary),
      description: translate('infant_description', 'text', dictionary, { older_age: 2 }),
      minValue: 0,
      isEnabled: true,
    },
    special: {
      name: 'special',
      defaultValue: 0,
      title: translate('pcd', 'label', dictionary),
      description: translate('pcd', 'text', dictionary),
      minValue: 0,
      isEnabled: true,
      isIndependent: true,
    },
    wheelchair_handicap: {
      name: 'wheelchair_handicap',
      defaultValue: 0,
      title: translate('pcd', 'label', dictionary),
      description: translate('pcd', 'text', dictionary),
      minValue: 0,
      isEnabled: true,
      isIndependent: true,
    },
    teacher: {
      name: 'teacher',
      defaultValue: 0,
      title: translate('professor', 'label', dictionary),
      description: translate('required_credential', 'text', dictionary),
      minValue: 0,
      isEnabled: false,
      isIndependent: true,
    },
    student: {
      name: 'student',
      defaultValue: 0,
      title: translate('student', 'label', dictionary),
      description: translate('required_credential', 'text', dictionary),
      minValue: 0,
      isEnabled: false,
      isIndependent: true,
    },
  };
};

export const getPassengersSelectorList = ({ passengers, lang }) => {
  if (!passengers) {
    return [];
  }
  return passengers.reduce((acc, passenger) => {
    const validPassengers = getPassengersTypes(lang)[passenger];
    if (validPassengers) {
      acc.push(validPassengers);
    }
    return acc;
  }, []);
};

export const getSeasonPassengersSelectorList = ({ passengers, lang }) => {
  if (!passengers) {
    return [];
  }
  return passengers.reduce((acc, passenger) => {
    const validPassengers = getPassengersTypes(lang)[passenger];
    if (validPassengers) {
      validPassengers.isEnabled = false;
      acc.push(validPassengers);
    }
    return acc;
  }, []);
};

import { useEffect, useRef, useState } from 'preact/hooks';
import UserPreferencesContext from './UserPreferencesContext';
import userPreferencesService from '../../services/userPreferences';
import useGrowthBookFeatures from '../GrowthBook';
import createUrl from '../../utils/createUrl';
import http from '../../services/http';
import { camelizeKeys } from 'humps';

const UserPreferencesProvider = ({ children, config }) => {
  const growthbookFeatures = useGrowthBookFeatures();
  const [userPreferences, setUserPreferences] = useState({
    originTerminal: '',
    destinationTerminal: '',
    originCityName: '',
    destinationCityName: '',
    origin: '',
    destination: '',
    requested: profileNotNeeded,
    hasPreferredRoute: false,
    hasProfile: false,
  });
  const preferredRoutesSetup = useRef();
  const { profileEnabled, profileAutocomplete, profileUrl } = config;
  const profileNotNeeded = !profileEnabled || !profileUrl || !profileAutocomplete;

  const getAutoCompleteUrl = () => {
    const { sourceUrl, line, airline, transporter, forceHttps, filters } = config;
    const sourceUrlToUse = growthbookFeatures?.funnel_version?.sourceUrl || sourceUrl;
    const protocol = window.location.protocol.includes('https') || forceHttps ? 'https:' : 'http:';
    const autocompleteUrl = createUrl(`${protocol}//${sourceUrlToUse}`);
    if (line) {
      autocompleteUrl.setQueryParam('line', line);
    } else if (airline) {
      autocompleteUrl.setQueryParam('airline', airline);
    } else if (transporter) {
      autocompleteUrl.setQueryParam('transporter', transporter);
    }
    if (filters) {
      autocompleteUrl.setQueryParam('filters', filters);
    }
    return autocompleteUrl;
  };

  /**
   * Matches the origin and destination city and terminal with the places retrieved from requestPlaces.
   * Sets the matched places in the user preferences and saves them in the cache if not already cached.
   */
  const setPreferredMatchedPlaces = ({
    originTerminal,
    destinationTerminal,
    originCityName,
    destinationCityName,
    places,
  }) => {
    // Functions to compare terminals and city names
    const compareTerminal = (a, b) => a.display?.toLowerCase() === b.toLowerCase();
    const compareCityName = (a, b) => a.cityName?.toLowerCase() === b.toLowerCase();

    // Find matched origin place based on terminal or city name
    let matchedOrigin = places.find(place => compareTerminal(place, originTerminal));
    if (!matchedOrigin)
      matchedOrigin = places.find(place => compareCityName(place, originCityName));

    // Find matched destination place based on terminal or city name
    let matchedDestination =
      matchedOrigin && places.find(place => compareTerminal(place, destinationTerminal));
    if (matchedOrigin && !matchedDestination)
      matchedDestination = places.find(place => compareCityName(place, destinationCityName));

    // Check if both origin and destination places are matched
    if (matchedOrigin && matchedDestination) {
      // Generate a timestamp for caching purposes
      const timestamp = new Date().getTime();

      // Prepare the preferences object with matched places and other flags
      const preferences = {
        ...userPreferences,
        timestamp,
        originPlace: matchedOrigin && camelizeKeys(matchedOrigin),
        destinationPlace: matchedDestination && camelizeKeys(matchedDestination),
        hasPreferredRoute: true,
        hasProfile: true,
      };

      // Check if cache exists; if not, directly set user preferences
      if (!userPreferencesService.getCache()) {
        setUserPreferences(preferences);
      }

      // Save preferences in the cache
      userPreferencesService.saveCache(preferences);
    } else {
      // If matching failed, update user preferences without preferred route
      setUserPreferences({
        ...userPreferences,
        hasPreferredRoute: false,
        hasProfile: true,
      });
    }

    // Set preferredRoutesSetup.current to indicate successful setup
    preferredRoutesSetup.current = true;
  };

  /**
   * Fetches places data from the API based on the provided source URL.
   * Resolves with the fetched data if successful, otherwise rejects with an error.
   * If data is retrieved and is non-empty, resolves with the data.
   * If data retrieval fails, sets preferredRoutesSetup.current to true to avoid
   * further requests to the API and rejects with an error.
   */
  const requestPlaces = () => {
    return new Promise((resolve, reject) => {
      // Get the source URL for fetching places
      const sourceUrl = getAutoCompleteUrl().href;

      // Send a GET request to fetch places data
      http
        .get(sourceUrl)
        .then(data => {
          // If data is retrieved and is non-empty, resolve with the data
          if (data && data.length) {
            resolve(data);
          }
        })
        .catch(() => {
          // If data retrieval fails, set preferredRoutesSetup.current to true
          // to avoid further requests to the API and reject with an error
          preferredRoutesSetup.current = true;
          reject();
        });
    });
  };

  /**
   * Fetches the available places based on provided parameters and sets up preferred terminals.
   * If successful, sets the originTerminal, destinationTerminal, originCityName,
   * and destinationCityName in the user preferences based on the fetched places data.
   * If there are matching terminals and cities found in the fetched places data,
   * updates the user preferences with the matched places and sets hasPreferredRoute to true.
   * If no matching places are found, sets hasPreferredRoute to false.
   */
  const setUpPreferredTerminals = async ({
    originTerminal,
    destinationTerminal,
    originCityName,
    destinationCityName,
  }) => {
    try {
      const places = await requestPlaces();
      setPreferredMatchedPlaces({
        originTerminal,
        destinationTerminal,
        originCityName,
        destinationCityName,
        places,
      });
    } catch {
      setUserPreferences(prevState => ({
        ...prevState,
        hasPreferredRoute: false,
      }));
    }
  };

  /**
   * Fetches the user profile data and updates the user preferences accordingly.
   * If successful, sets the originTerminal, destinationTerminal, originCityName,
   * and destinationCityName in the user preferences based on the fetched profile data.
   * Sets the requested flag to true and marks hasProfile as true if profile fetching is successful.
   * If there's an error fetching the profile, sets hasProfile as false and keeps the requested flag true.
   */
  async function getProfile() {
    try {
      // Fetch user profile data from userPreferencesService using the provided profileUrl
      const profile = await userPreferencesService.getUserProfile({ profileUrl });

      // Destructure preferences from the fetched profile data
      const { preferences } = profile;
      const {
        originTerminal,
        destinationTerminal,
        origin: originCityName,
        destination: destinationCityName,
      } = preferences;

      // Update user preferences with fetched profile data
      setUserPreferences({
        originTerminal,
        destinationTerminal,
        originCityName,
        destinationCityName,
        requested: true,
        hasProfile: true,
      });
    } catch {
      // If there's an error fetching the profile, update user preferences accordingly
      setUserPreferences(prevState => ({
        ...prevState,
        requested: true,
        hasProfile: false,
      }));
    }
  }

  /**
   * Sets the user preferences from the cached data.
   * Retrieves the cached preferences using the userPreferencesService.getCache() method
   * and updates the user preferences accordingly.
   * This function also sets the hasPreferredRoute and hasProfile flags to true
   * and marks the preferredRoutesSetup as completed.
   */
  function setUserPreferencesFromCache() {
    const cache = userPreferencesService.getCache();

    // Update user preferences with cached data and set flags
    setUserPreferences({
      ...cache, // Spread operator to merge cache into user preferences
      hasPreferredRoute: true,
      hasProfile: true,
    });

    // Mark preferredRoutesSetup as completed
    preferredRoutesSetup.current = true;
  }

  useEffect(() => {
    if (profileNotNeeded) return;

    if (userPreferencesService.getCache() && !userPreferencesService.isCacheOld()) {
      setUserPreferencesFromCache();
    }
    if (window.mixpanel && window.mixpanel.__loaded) {
      getProfile();
    } else {
      window.addEventListener('MixpanelReady', getProfile);
    }
    return () => {
      window.removeEventListener('MixpanelReady', getProfile);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const {
      originTerminal,
      destinationTerminal,
      originCityName,
      destinationCityName,
    } = userPreferences;
    if (
      preferredRoutesSetup.current ||
      (!originTerminal && !destinationTerminal) ||
      (!originCityName && !destinationCityName)
    )
      return;
    setUpPreferredTerminals(userPreferences);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPreferences]);

  return (
    <UserPreferencesContext.Provider value={userPreferences}>
      {children}
    </UserPreferencesContext.Provider>
  );
};

export default UserPreferencesProvider;

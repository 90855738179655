import { useContext } from 'preact/hooks';
import GrowthBookFeaturesContext from './GrowthBookFeaturesContext';

const useGrowthBookFeatures = () => {
  const growthBookFeaturesContext = useContext(GrowthBookFeaturesContext);

  if (!growthBookFeaturesContext) {
    throw new Error('useGrowthBookFeatures must be used within a GrowthBookFeaturesProvider');
  }
  return growthBookFeaturesContext;
};

export default useGrowthBookFeatures;

import { createIsland } from 'preact-island';
import SearchHub from './components/SearchHub';

const isDynamicWidget = document.currentScript.dataset.dynamicWidgetCreation === 'true';

// If the script has the data set 'dynamic-widget-creation' the widget will be able to be created through the window object,
if (isDynamicWidget) {
  window.createSearchWidget = ({ selector, clean }) => {
    const widgetIsland = createIsland(SearchHub);
    widgetIsland.render({
      selector,
      clean,
    });
    return widgetIsland;
  };
  window.dispatchEvent(new Event('searchWidgetReady'));
} else {
  const widgetIsland = createIsland(SearchHub);
  // If it is not a dynamic widget, it is automatically created with the data-widget-host attribute
  widgetIsland.render({
    selector: '[data-widget-host="habitat"]',
    clean: true,
  });
}

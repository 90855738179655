const blacklist = ['de', 'del'];

const titleizeWord = word => `${word.charAt(0).toUpperCase()}${word.slice(1).toLowerCase()}`;

export default function (string) {
  return string
    .split(' ')
    .map(word => (word.length > 1 && !blacklist.includes(word) ? titleizeWord(word) : word))
    .join(' ');
}

import TabHeaderItem from './TabHeaderItem';
import '../style.scss';

const Tabs = ({ children, activeTab = 0, onChangeTab }) => {
  return (
    <div className="tabs">
      <ul className="tabs-list" role="tablist">
        {children.map((tab, index) => {
          const isActive = activeTab === index;
          const { title, icon } = tab.props;
          const key = `${title}-${index}`;

          return (
            <TabHeaderItem
              title={title}
              icon={icon}
              key={key}
              active={isActive}
              onSelect={() => onChangeTab(index)}
            />
          );
        })}
      </ul>
      <div className="tabs-content">
        {children.map((content, index) => {
          const isActive = activeTab === index;

          return <div className={!isActive ? 'hide' : ''}>{content}</div>;
        })}
      </div>
    </div>
  );
};

export default Tabs;

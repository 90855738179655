import probability from './probability';

const COOKIE = 'WIDGET-AB-TEST-01';

export function getTest(cookieItem) {
  const itemName = !cookieItem ? COOKIE : cookieItem;
  return localStorage.getItem(itemName);
}

export function initTest(value, cookieItem) {
  const itemName = !cookieItem ? COOKIE : cookieItem;
  if (localStorage.getItem(itemName)) return getTest(cookieItem);

  const isA = probability(value);
  localStorage.setItem(itemName, isA ? 'a' : 'b');

  return isA ? 'a' : 'b';
}

export function emitTest(result) {
  if (typeof window === 'undefined') return;

  const event = new CustomEvent('ab-test', { detail: result });
  window.dispatchEvent(event);
}

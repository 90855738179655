import validatePassengers from './validatePassengers';

function isEmpty(string) {
  return !string || string === '';
}

export default function validate({
  origin,
  destination,
  departureDate,
  returnDate,
  tripType,
  passengers,
}) {
  const errors = {};

  if (!origin || !origin.slug) {
    errors.origin = 'Selecciona un origen';
  }

  if (!destination || !destination.slug) {
    errors.destination = 'Selecciona un destino';
  }

  if (destination === origin) {
    errors.destination = 'Elige destino diferente';
  }

  if (tripType !== 'openTicket' && isEmpty(departureDate)) {
    errors.departureDate = 'Elige una fecha';
  }

  if (tripType === 'round' && isEmpty(returnDate)) {
    errors.returnDate = 'Elige una fecha';
  }

  if (validatePassengers(passengers)) {
    errors.passengers = 'Solo puede haber 5 pasajeros como maximo';
  }

  return errors;
}

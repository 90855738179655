export default function decodePassengers(encodedPassengers) {
  const passengerTypes = encodedPassengers ? encodedPassengers.split('-') : ['A1'];

  return passengerTypes.reduce(
    (passengers, current) => {
      const type = current.substr(0, 1);
      const quantity = Number(current.substr(1));
      const decodedPassengers = passengers;

      if (!quantity) return decodedPassengers;

      switch (type) {
        case 'A':
          decodedPassengers.adult += quantity;
          break;
        case 'C':
          decodedPassengers.children += quantity;
          break;
        case 'I':
          decodedPassengers.infant += quantity;
          break;
        default:
          return passengers;
      }

      return decodedPassengers;
    },
    { adult: 0, children: 0, infant: 0 }
  );
}

export const getPassengersSelectorInitialState = ({ passengers, searchValues }) => {
  return passengers.reduce((acc, passenger) => {
    let value = passenger.defaultValue;
    if (searchValues && Object.keys(searchValues).length) {
      value = searchValues[passenger.name] || 0;
    }
    acc = {
      ...acc,
      [passenger.name]: value,
    };
    return acc;
  }, {});
};

export const decodePassengersValues = ({ passengers }) => {
  const passengersWithValue = passengers.trim().split(',');

  return passengersWithValue.reduce((acc, passenger) => {
    const passengerValue = passenger.split('=');

    if (passengerValue.length === 1) return acc;

    acc = {
      ...acc,
      [passengerValue[0]]: Number(passengerValue[1]),
    };
    return acc;
  }, {});
};

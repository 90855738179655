import { IntlProvider } from 'preact-i18n';
import translations from '../translations/i18n';
import useWidgetConfig from '../context/WidgetConfig';

const QuantitySelectorItem = ({ name, title, description, isDisabled, value = 0, onChange }) => {
  const handleOnChange = ({ name, operation }) => () => {
    onChange({ name, value: value + operation, operation });
  };
  return (
    <div className="quantity-selector-item">
      <div className="quantity-selector-item-content">
        <div className="quantity-selector-item-info">
          <p className={`sw-title ${isDisabled ? 'sw-title-disabled' : ''}`}>{title}</p>
          {description && (
            <p className={`sw-description ${isDisabled ? 'sw-description-disabled' : ''}`}>
              {description}
            </p>
          )}
        </div>
      </div>

      <div class="quantity-selector-controls">
        <button className="control" onClick={handleOnChange({ name, operation: -1 })}>
          <i class="icon icon-decrement" />
        </button>
        <span class="counter">{value}</span>
        <button className="control" onClick={handleOnChange({ name, operation: 1 })}>
          <i class="icon icon-increment" />
        </button>
      </div>
    </div>
  );
};

const QuantitySelectorList = ({
  items = [],
  maxTotalItems,
  minTotalItems,
  beforeIncrement,
  beforeDecrement,
  onChange,
  error,
}) => {
  const { i18nLng } = useWidgetConfig();

  /**
   * Returns if the maximum or minimum items total has been reached.
   * @param {Boolean} isIncrement - Indicates if the operation is an addition.
   * @returns {Boolean}
   */
  const maxOrMinItemsReached = isIncrement => {
    const count = items.reduce((acc, currentItem) => {
      return (acc += currentItem.value);
    }, 0);
    return isIncrement ? count === maxTotalItems : count === minTotalItems;
  };

  const minItemValueReached = ({ name, value }) => {
    const item = items.find(el => el.name === name);
    return value < item.minValue;
  };

  /**
   * Handle the items quantity value change.
   * @param {Object} params - Function params.
   * @param {String} params.name - item name changed.
   * @param {Number} params.value - item new value.
   * @param {Number} params.operation - value added.
   */
  const handleOnChange = ({ name, value, operation }) => {
    const isIncrement = operation > 0;

    if (!isIncrement && minItemValueReached({ name, value })) return;
    if (maxOrMinItemsReached(isIncrement)) return;

    if (isIncrement) {
      if (beforeIncrement({ name, isIncrement, items, newValue: value })) {
        onChange({ name, value });
      }
      return;
    }
    if (beforeDecrement({ name, isIncrement, items, newValue: value })) {
      onChange({ name, value });
    }
  };

  return (
    <IntlProvider definition={translations[i18nLng]}>
      <div className={`quantity-selector ${error ? 'error' : ''}`}>
        <div className="quantity-selector-menu">
          {items.map(({ name, title, description, value }) => (
            <QuantitySelectorItem
              key={name}
              name={name}
              title={title}
              description={description}
              value={value}
              onChange={handleOnChange}
            />
          ))}
        </div>
      </div>
    </IntlProvider>
  );
};

export default QuantitySelectorList;

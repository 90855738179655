import { Text } from 'preact-i18n';

const TabHeaderItem = ({ title, active, icon, onSelect = () => {} }) => {
  return (
    <li role="presentation" className={active ? 'active' : ''} onClick={onSelect}>
      <a role="tab" className={icon}>
        <Text id={`tab.${title}`} />
      </a>
    </li>
  );
};

export default TabHeaderItem;

import useWidgetConfig from './useWidgetConfig';

export default function witWidgetConfig(Component) {
  const WrapperComponent = props => {
    const config = useWidgetConfig();
    return <Component {...props} config={config} />;
  };

  return WrapperComponent;
}

import { h } from 'preact';
import RouteOption from '../RouteOption';

const RecommendedRoutesList = ({ routesGroups, onSelect }) => {
  const showRecommendedBadge = {
    recommended: true,
  };
  const showRecommendedIcon = {
    recommended: true,
    popularDestinations: true,
  };

  return routesGroups.map(group => {
    return (
      <div className="wrapper" key={group.type}>
        {group.routes.map(route => {
          const routeKey =
            group.type !== 'popularDestinations'
              ? `${route.origin.slug} - ${route.destination.slug}`
              : route.destination.slug;
          return (
            <RouteOption
              key={routeKey}
              route={route}
              showOrigin={group.type !== 'popularDestinations'}
              showRecommendedBadge={showRecommendedBadge[group.type]}
              showRecommendedIcon={showRecommendedIcon[group.type]}
              onSelect={onSelect}
            />
          );
        })}
      </div>
    );
  });
};

export default RecommendedRoutesList;

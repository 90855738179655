import { GrowthBook } from "@growthbook/growthbook";
import Cookies from "js-cookie";
import { browserName, deviceType, osName } from 'react-device-detect';
import { supported as supportedLang, DEFAULT_LANG } from '../translations/i18n';

let growthBook;

/**
 * Function to set attributes to GrowthBook
 */
export const setGrowthBookAttributes = (attributes) => {
  // This method is called from other flows so we need to check if the growthBook instance is defined
  if (!growthBook) return;

  growthBook.setAttributes({
    ...growthBook.getAttributes(),
    ...attributes,
  });
};

/**
 * Initialize GrowthBook and assign to the instance
 */
export const initGrowthBook = (brand, growthbookApi, isDev, lang) => {
  growthBook = new GrowthBook({
    // enableDevMode: true allows you to use the Chrome DevTools Extension to test/debug.
    enableDevMode: isDev,
    // Callback when a user is put into an A/B experiment
    trackingCallback: (experiment, result) => {
      // Send the experiment data to mixpanel
      window.mixpanel.track('$experiment_started', {
        'Experiment name': experiment.key,
        'Variant value': result.value,
        '$source': 'growthbook',
      });
    },
  });
  return new Promise(async (resolve) => {
    const id = window.mixpanel.get_distinct_id();
    const isDesktop = window.matchMedia('only screen and (min-width: 641px)')
    const browser = browserName.toLowerCase();
    const device = deviceType.toLowerCase();
    const os = osName.toLowerCase();

    const isSupported = supportedLang.indexOf(lang) > -1;
    const selectedLang = isSupported ? lang : DEFAULT_LANG;
  
    // Set GrowthBook initial attributes
    setGrowthBookAttributes({
      brand,
      language: selectedLang,
      product: isDesktop.matches ? 'web' : 'web-mobile',
      logged: Boolean(Cookies.get('token')),
      browser,
      device,
      os,
      id,
    });

    // Fetch features from GrowthBook
    fetch(growthbookApi)
    .then((res) => res.json())
    .then((json) => {
      growthBook.setFeatures(json.features);
      resolve();
    }).catch(() => {
      console.error('Growthbook can\'t get the features');
    });
  })
};

/**
 * Get the current GrowthBook instance
 * @returns GrowthBook instance or undefined
 */
export const getGrowthBookInstance = () => {
  return window.growthBook || growthBook;
};

/**
 * Returns an object with the current feature values for the given features.
 * @param {Array} features - An array of feature names to retrieve values for.
 * @returns {Object} - An object where each property corresponds to a feature name.
 */
export const getGrowthBookFeatures = (features, brand) => {
  const gb = getGrowthBookInstance();

  const featuresObject = {};
  features.forEach((feature) => {
    featuresObject[feature] = gb?.getFeatureValue(`${feature}_${brand}`);
  });
  return featuresObject;
};
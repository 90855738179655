import hand from "../../assets/images/whatsapp-cta/waving-hand.webp";
import whatsapp from "../../assets/images/whatsapp-cta/whatsapp-icon.svg";
import { Text } from 'preact-i18n';
import mixpanelTracker from "../utils/mixpanelTracker";

const WhatsappCta = ({ whatsappUrl, whatsappCopy }) => {

  if (!whatsappUrl) return null; 
  const handleOnClick = () => {
    mixpanelTracker.trackEvent('Search Whatsapp CTA Clicked');
  }
  return (
      <a className="whatsapp-link" onClick={handleOnClick} href={whatsappUrl} target="_blank" rel="noreferrer">
        <img className="whatsapp-wave" src={hand} alt="waving-hand-emoji" />

        <Text id={`text.${whatsappCopy}`} />

        <span className="whatsapp-button">
          <img className="whatsapp-logo" src={whatsapp} alt="whatsapp-logo" />
          WhatsApp
        </span>
      </a>
  );
};

export default WhatsappCta;

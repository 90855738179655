import useGrowthBookFeatures from './useGrowthBookFeatures';

const withGrowthBookFeatures = Component => {
  const WrappedComponent = props => {
    const growthbookFeatures = useGrowthBookFeatures();

    return <Component {...props} growthbookFeatures={growthbookFeatures} />;
  };
  return WrappedComponent;
};

export default withGrowthBookFeatures;

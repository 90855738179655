import { useContext } from 'preact/hooks';
import WidgetConfigContext from './WidgetConfigContext';

const useWidgetConfig = () => {
  const widgetConfigContext = useContext(WidgetConfigContext);

  if (!widgetConfigContext) {
    throw new Error('useWidgetConfig must be used within a WidgetConfigProvider');
  }
  return widgetConfigContext;
};

export default useWidgetConfig;

import { camelizeKeys, decamelizeKeys } from 'humps';
const pollingTimeout = 2000;

const HttpService = () => {
  const request = ({ url, method, body }) => {
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    };
  
    return new Promise((resolve, reject) => {
      fetch(url, {
        body,
        headers,
        method,
      })
        .then((response) => response.json())
        .then((data) => resolve(camelizeKeys(data)))
        .catch((error) => reject(error));
    })
  
  }
  
  /**
   * Post a request to the API
   * @param {Object} options - The options for the request
   * @param {String} options.url - The url to send the request to
   * @param {Object} options.payload - The payload for the request
   * @returns 
   */
  const post = ({ url, payload }) => {
    const payloadDecamelized = decamelizeKeys(payload);
    const body = typeof payload === 'string' ? payloadDecamelized : JSON.stringify(payloadDecamelized);
    return request(
      {
        url,
        method: 'POST',
        body,
      },
    );
  }
  
  /**
   * Get a request from the API
   * @param {Object} options - The options for the request
   * @param {String} options.url - The url to send the request to
   * @returns 
   */
  const get = (url) => {
    return request({ url, method: 'GET' });
  }
  
  function pollEndpoint({ url, retries = 15 }) {
    return new Promise((resolve, reject) => {
      get(url)
      .then((response) => {
        const remainRetries = retries - 1;
  
        const { state } = response; 
  
        if (!retries) {
          reject({ status: 'retries_exceeded'});
          return;
        }
  
        if (state === 'failed') {
          reject({ status: state })
          return;
        };
  
        if (state === 'pending') {
          setTimeout(() => {
            pollEndpoint({ url, retries: remainRetries })
            .then((response) => resolve(response))
            .catch((error) => reject(error))
          }, pollingTimeout);
        } else {
          resolve(response);
        }
      });
    })
  }

  return {
    get,
    post,
    pollEndpoint,
  }
}

const httpService = HttpService();
export default httpService;
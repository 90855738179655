function isEmpty(string) {
  return !string || string === '';
}

export default function validateHotel({ place, startDate, endDate }) {
  const errors = {};

  if (!place || !place.slug) {
    errors.place = 'Selecciona una ciudad';
  }

  if (isEmpty(startDate)) {
    errors.startDate = 'Elige una fecha';
  }

  if (isEmpty(endDate)) {
    errors.endDate = 'Elige una fecha';
  }

  return errors;
}

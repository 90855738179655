import { useEffect, useState } from 'preact/hooks';
import { getRecentSearches } from '../../utils/recentTrips';
import { camelizeKeys } from 'humps';
import useWidgetConfig from '../WidgetConfig';
import usePlaces from '../Places';

const useRecommendedRoutes = ({ showRecentSearches, showPopularDestinations }) => {
  const [recentSearches, setRecentSearches] = useState({});
  const { maxRecentSearches, recentSearchesLS } = useWidgetConfig();
  const currentPlaces = usePlaces();

  const RECENT_SEARCHES = 'recentSearches';

  const hasRecentSearches = Boolean(Object.keys(recentSearches).length);

  const setUpRecentSearches = () => {
    if (!showRecentSearches || !maxRecentSearches) return;

    const recentSearchesFormatted = getRecentSearches(maxRecentSearches, recentSearchesLS).map(
      recentSearch => ({
        ...recentSearch,
        origin: {
          ...recentSearch.origin,
          recentSearch: true,
        },
        destination: {
          ...recentSearch.destination,
          recentSearch: true,
        },
      })
    );

    setRecentSearches({
      type: RECENT_SEARCHES,
      routes: camelizeKeys(recentSearchesFormatted),
    });
  };

  const createRecommendedList = () => {
    const recommendedRoutes = [];

    if (hasRecentSearches) {
      const recentSearchesRoutes = { routes: [...recentSearches.routes] };
      if (recentSearchesRoutes.routes?.length) recommendedRoutes.push(recentSearchesRoutes);
    }

    if (showPopularDestinations && currentPlaces?.places?.length) {
      recommendedRoutes.push({
        type: 'popularDestinations',
        routes: camelizeKeys(
          currentPlaces.places.slice(0, 3).map(place => ({
            destination: {
              ...place,
              isPopularDestination: true,
            },
          }))
        ),
      });
    }
    return recommendedRoutes;
  };

  useEffect(() => {
    setUpRecentSearches();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // TODO: Add the recommended routes by profile too
  const recommendedRoutes = createRecommendedList();
  return {
    exists: Boolean(recommendedRoutes.length),
    recommendedRoutes,
  };
};

export default useRecommendedRoutes;

import { selectIcon } from '../templates/place';
import { Text } from 'preact-i18n';

const LabelsNearestLocation = ({ flatVariant, locationCopy }) => {
  
  return (
      <div className={`nearest ${!flatVariant ? 'nearest-variant' : ''}`}>
        <div className="nearest-tag">
          <div className="nearest-logo"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: selectIcon('location', false),
            }}
          />
          <div />

          <span>
           <Text id={`text.${locationCopy}`} />
          </span>
        </div>
      </div>
  );
};

export default LabelsNearestLocation;

export default function normalize(raw) {
  const chars = {
    á: 'a',
    é: 'e',
    í: 'i',
    ó: 'o',
    ú: 'u',
  };
  const expr = /[áéíóú]/gi;

  return raw
    .toLowerCase()
    .replace(expr, char => chars[char])
    .replace(/ +(?= )/g, '');
}

import useUserPreferences from './useUserPreferences';

export default function withUserPreferences(Component) {
  const WrapperComponent = props => {
    const userPreferences = useUserPreferences();
    return <Component {...props} userPreferences={userPreferences} />;
  };

  return WrapperComponent;
}

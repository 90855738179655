/**
 * Sets data in GTM's data layer
 *
 * The searchProperties object required for some of the methods of this module can be:
 *
 * searchProperties: {
 *   originCity: 'Monterrey',
 *   originState: 'Nuevo León',
 *   originCountry: 'México',
 *   destinationCity: 'Dallas',
 *   destinationState: 'Texas',
 *   destinationCountry: 'United States',
 *   startDate: 'Thu Mar 07 2019 13:15:31 GMT-0600', // Javascript Date object
 *   endDate: 'Thu Mar 10 2019 13:15:31 GMT-0600', // Javascript Date object (optional)
 *   travelers: {
 *     adult: 2
 *     child: 0
 *     infant: 1
 *   },
 *   searchUrl: 'https://viaje.reservamos.mx/search/.../' // Url to open new tab with search results
 * }
 */

import addDate from './addDate';

const countryCodes = {
  'México': 'MX',
  'United States': 'US',
  'Argentina': 'AR',
  'Colombia': 'CO',
};

function dlPush(data) {
  if (window.dataLayer) {
    window.dataLayer.push(data);
  } else {
    console.warn('GTM ➞ GTM not available', data);
  }
}

function formatDate(date) {
  if (!(date instanceof Date) || Number.isNaN(date.getTime())) return null;

  const zeroPad = number => (number < 10 ? `0${number}` : String(number));
  const year = String(date.getFullYear());
  const month = zeroPad(date.getMonth() + 1);
  const day = zeroPad(date.getDate());

  return `${month}/${day}/${year}`;
}

/**
 * Sends an event to GTM to trigger an Intent Media exit unit
 *
 * @param  {Object} searchProperties An object as decribed above
 */
export default function gtmSetDataLayer({
  startDate,
  endDate,
  destinationCity,
  destinationState,
  destinationCountry,
  originCity,
  originState,
  originCountry,
  travelers,
}) {
  const initialDate = startDate || addDate(new Date(), 1);

  const searchProperties = {
    destinationCity,
    destinationState,
    destinationCountry,
    originCity,
    originState,
    originCountry,
    ...travelers,
    startDate: startDate && formatDate(initialDate),
    destinationCountryCode: countryCodes[destinationCountry],
    originCountryCode: countryCodes[originCountry],
    oneWay: !endDate,
  };

  if (startDate) {
    if (endDate) {
      searchProperties.endDate = formatDate(endDate);
    } else {
      searchProperties.endDate = formatDate(addDate(initialDate, 2));
    }
  }

  dlPush({
    event: 'Set Search',
    searchProperties,
  });
}

export default function createUrl (url = window.location.href) {
  const regex = /^((?:http[s]?:)\/\/)?([^:/]+)([^\w\-..]+[^?#]+)(\?[^#]+)?(#.*)?/i;
  const [, scheme = '', host, path = '', query = '?', fragment = ''] = regex.exec(url);
  const queryParams = query
    .substr(1)
    .split('&')
    .map(segment => segment.split('='))
    .filter(([segment]) => segment)
    .reduce((params, [key, value = '']) => ({ ...params, [key]: value }), {});

  return {
    fragment,
    host,
    path,
    scheme,
    queryParams,

    get queryString () {
      const result = Object.keys(this.queryParams)
        .map(key => `${key}=${this.queryParams[key]}`)
        .join('&');

      return result ? `?${result}` : '';
    },

    get href () {
      return `${this.scheme}${this.host}${this.path}${this.queryString}${this.fragment}`;
    },

    setQueryParam (key, value) {
      this.queryParams[key] = value;
      return this;
    },

    removeQueryParams (...keys) {
      keys.forEach(key => delete queryParams[key]);
      return this;
    },
  };
}
